/*
| Developed by Starton
| Filename : WhiteLabelNewPresetForm.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { Box, Divider, PaletteMode, Typography, useTheme } from '@mui/material'
import * as Yup from 'yup'
import useTranslate from 'next-translate/useTranslation'
import { Formik, FormikHelpers } from 'formik'
import { useSWRConfig } from 'swr'
import { ApiCommonErrorsHandler } from '@/services/api/starton-extended/errors/errors.service'
import { WhiteLabelNewPresetFormFields } from '@/components/white-labelling/drawer/views/new/WhiteLabelNewPresetFormFields'
import { useWhiteLabel } from '@/components/white-labelling/context/whitelabel.context'
import { axiosFAPIInstance, FAPIUpload } from '@/services/fapi'
import StartonUtils from '@/utils/starton.utils'
import { FAPI_PLATFORMS } from '@/services/fapi/hooks/usePlatform'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface WhiteLabelNewPresetFormProps {
	handleBack: VoidFunction
}

export interface WhiteLabelNewPresetFormValues {
	name: string
	logo: File | null
	logoType: File | null
	colorMode: boolean
	twitterPageUrl?: string
	featuredFlags: {
		poweredBy: boolean
		enableRelayer: boolean
		enableListener: boolean
		enablePlayground: boolean
		enableRPCNode: boolean
	}
	theme: {
		primary: {
			main: string
			dark: string
			light: string
			hover: string
			contrastText: string
		}
	}
	apiKey: string
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const WhiteLabelNewPresetForm: React.FC<WhiteLabelNewPresetFormProps> = ({ handleBack }) => {
	const { t } = useTranslate()
	const { mutate } = useSWRConfig()
	const theme = useTheme()
	const whiteLabel = useWhiteLabel()

	// Form
	// ----------------------------------------------------------------------------
	const initialValues = React.useMemo(() => {
		return {
			name: '',
			logo: null,
			logoType: null,
			colorMode: whiteLabel?.colorMode === 'light' ?? false,
			twitterPageUrl: whiteLabel.twitterPageUrl ?? '',
			featuredFlags: {
				poweredBy: whiteLabel.featuredFlags.poweredBy ?? false,
				enableRelayer: whiteLabel.featuredFlags.enableRelayer ?? false,
				enableListener: whiteLabel.featuredFlags.enableListener ?? false,
				enablePlayground: whiteLabel.featuredFlags.enablePlayground ?? false,
				enableRPCNode: whiteLabel.featuredFlags.enableRPCNode ?? false,
			},
			theme: {
				primary: {
					main: theme.palette.primary.main,
					dark: theme.palette.primary.dark,
					light: theme.palette.primary.light,
					hover: theme.palette.primary.hover,
					contrastText: theme.palette.primary.contrastText,
				},
			},
			apiKey: '',
		} as unknown as WhiteLabelNewPresetFormValues
	}, [
		theme.palette.primary.contrastText,
		theme.palette.primary.dark,
		theme.palette.primary.hover,
		theme.palette.primary.light,
		theme.palette.primary.main,
		whiteLabel?.colorMode,
		whiteLabel.featuredFlags.enableListener,
		whiteLabel.featuredFlags.enablePlayground,
		whiteLabel.featuredFlags.enableRelayer,
		whiteLabel.featuredFlags.poweredBy,
		whiteLabel.featuredFlags.enableRPCNode,
		whiteLabel.twitterPageUrl,
	])
	const validationSchema = React.useMemo(() => {
		return Yup.object().shape({
			name: Yup.string().required(),
			logo: Yup.mixed()
				// @ts-ignore
				.test('fileFormat', t('common:yup.mixed.fileFormat'), (value?: File) => {
					if (!value) return true // Allow empty array
					// png, jpg, jpeg and svg
					return Boolean(
						value && ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'].includes(value.type),
					)
				})
				// @ts-ignore
				.test('fileSize', t('common:yup.mixed.fileSize'), (value?: File) => {
					if (!value) return true // Allow empty array
					return value && value.size <= 20 * 1024 * 1024
				}),
			logoType: Yup.mixed()
				// @ts-ignore
				.test('fileFormat', t('common:yup.mixed.fileFormat'), (value?: File) => {
					if (!value) return true // Allow empty array
					return Boolean(
						value && ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'].includes(value.type),
					)
				})
				// @ts-ignore
				.test('fileSize', t('common:yup.mixed.fileSize'), (value?: File) => {
					if (!value) return true // Allow empty array
					return value && value.size <= 20 * 1024 * 1024
				}),
			colorMode: Yup.boolean(),
			twitterPageUrl: Yup.string(),
			featuredFlags: Yup.object().shape({
				poweredBy: Yup.boolean().required(),
				enableRelayer: Yup.boolean().required(),
				enableListener: Yup.boolean().required(),
				enablePlayground: Yup.boolean().required(),
				enableRPCNode: Yup.boolean().required(),
			}),
			theme: Yup.object().shape({
				primary: Yup.object().shape({
					main: Yup.string().required(),
					dark: Yup.string().required(),
					light: Yup.string().required(),
					hover: Yup.string().required(),
					contrastText: Yup.string().required(),
				}),
			}),
			apiKey: Yup.string().required(),
		})
	}, [t])

	// Methods
	// ----------------------------------------------------------------------------
	const handleSubmit = async (
		values: WhiteLabelNewPresetFormValues,
		formikHelpers: FormikHelpers<WhiteLabelNewPresetFormValues>,
	) => {
		const { apiKey, ...restValues } = values

		try {
			// Prepare form data
			const formDataLogo = new FormData()
			formDataLogo.append(
				'files',
				restValues.logo as unknown as File,
				`${StartonUtils.sanitizeString(restValues.name)}-logo.png`,
			)
			formDataLogo.append('ref', 'api::platform.platform')

			const formDataLogoType = new FormData()
			formDataLogoType.append(
				'files',
				restValues.logoType as unknown as File,
				`${StartonUtils.sanitizeString(restValues.name)}-logo-type.png`,
			)
			formDataLogoType.append('ref', 'api::platform.platform')

			// Upload logo and logoType
			const [logoResponse, logoTypeResponse] = await Promise.all([
				await axiosFAPIInstance<Array<FAPIUpload>>({
					method: 'POST',
					url: '/api/upload',
					data: formDataLogo,
					headers: {
						Authorization: `Bearer ${apiKey}`,
						'Content-Type': 'multipart/form-data',
					},
				}),
				await axiosFAPIInstance<Array<FAPIUpload>>({
					method: 'POST',
					url: '/api/upload',
					data: formDataLogoType,
					headers: {
						Authorization: `Bearer ${apiKey}`,
						'Content-Type': 'multipart/form-data',
					},
				}),
			])

			// Add presets
			await axiosFAPIInstance({
				method: 'POST',
				url: '/api/platforms',
				data: {
					data: {
						name: restValues.name,
						slug: StartonUtils.sanitizeString(restValues.name),
						logo: logoResponse.data[0].id,
						logoType: logoTypeResponse.data[0].id,
						colorMode: restValues.colorMode ? ('light' as PaletteMode) : ('dark' as PaletteMode),
						twitterPageUrl: restValues.twitterPageUrl,
						featuredFlags: restValues.featuredFlags,
						theme: restValues.theme,
					},
				},
				headers: {
					Authorization: `Bearer ${apiKey}`,
				},
			})

			// Mutate list
			await mutate(FAPI_PLATFORMS)

			// White label update
			whiteLabel.onUpdate({
				name: restValues.name,
				slug: StartonUtils.sanitizeString(restValues.name),
				logo: logoResponse.data[0].url,
				logoType: logoTypeResponse.data[0].url,
				colorMode: restValues.colorMode ? ('light' as PaletteMode) : ('dark' as PaletteMode),
				twitterPageUrl: restValues.twitterPageUrl,
				featuredFlags: {
					enableListener: restValues.featuredFlags.enableListener,
					enableRelayer: restValues.featuredFlags.enableRelayer,
					poweredBy: restValues.featuredFlags.poweredBy,
					enablePlayground: restValues.featuredFlags.enablePlayground,
					enableRPCNode: restValues.featuredFlags.enableRPCNode,
				},
				theme: {
					palette: {
						primary: {
							main: restValues.theme.primary.main,
							dark: restValues.theme.primary.dark,
							light: restValues.theme.primary.light,
							hover: restValues.theme.primary.hover,
							contrastText: restValues.theme.primary.contrastText,
						},
					},
				},
				isStarton: false,
			})

			// Handle back
			handleBack()
		} catch (error) {
			ApiCommonErrorsHandler(error, t)
		} finally {
			formikHelpers.setSubmitting(false)
		}
	}

	// Render
	// ----------------------------------------------------------------------------
	return (
		<Box display={'flex'} flexDirection={'column'} gap={2} flex={1}>
			<Box display={'flex'} flexDirection={'column'} gap={1}>
				<Typography variant="subtitle2">{t('common:whitelabel.drawer.new.title')}</Typography>
				<Typography>{t('common:whitelabel.drawer.new.description')}</Typography>
			</Box>
			<Divider />
			<Formik<WhiteLabelNewPresetFormValues>
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validationSchema={validationSchema}
			>
				{(formikProps) => <WhiteLabelNewPresetFormFields formikProps={formikProps} handleBack={handleBack} />}
			</Formik>
		</Box>
	)
}
